import React, { useState, useEffect, useRef } from "react";
import HeroImage from "../images/releases/hero_concert_1_2.webp";
import HeroVideo from "../images/video/studio_video.mp4";
import AOS from "aos";
import "aos/dist/aos.css";

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isMdOrHigher = windowWidth >= 768;

  return (
    <div className="relative h-[95vh] flex items-center justify-start w-[100%] bg-black">
      <div className="absolute inset-0 z-0">
        {isMdOrHigher ? (
          <video
            src={HeroVideo}
            alt="Hero"
            loading="lazy"
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
            className="opacity-60 blur-sm"
            loop
            muted
            autoPlay
          />
        ) : (
          <img
            src={HeroImage}
            alt="Hero"
            loading="lazy"
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
            className="opacity-50"
          />
        )}
      </div>

      <div className="relative z-1 flex flex-col mx-5 md:ml-10">
        <h6
          className="text-3xl w-[90%] md:w-[60%] text-white"
          data-aos="zoom-in-right"
        >
          Welcome to
        </h6>
        <h1 className="text-7xl md:text-9xl mb-4 text-white" data-aos="flip-up">
          Momentone
        </h1>
        <h6
          className="text-xl w-[90%] md:w-[60%] ml-1 text-white"
          data-aos="zoom-in-left"
        >
          Nestled in the beating heart of Stockholm, Momentone emerges as an
          exciting new player in the world of music.
        </h6>
      </div>
    </div>
  );
};

export default Hero;
