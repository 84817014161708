import React, { useState, useEffect } from "react";
import Logo from "../images/momentone_logo_versions_black_square.png";
import { Link } from "react-router-dom";
import { FaBars, FaTimes, FaInstagram } from "react-icons/fa";
import { navlinks } from "../constants/Data";
import AOS from "aos";
import "aos/dist/aos.css";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <div className="flex justify-between bg-white shadow-lg	pr-10 z-50">
      <div className="flex mx-0 justify-center">
        <Link to="/">
          <img
            src={Logo}
            className="w-28 hover:opacity-80"
            onClick={closeMenu}
            data-aos="fade-down"
          />
        </Link>
      </div>
      <ul className="flex space-x-10 mt-7">
        {navlinks.map((navlink, index) => (
          <li className="hidden md:flex" key={index}>
            <Link to={navlink.url}>
              <h1
                className="hover-underline-animation uppercase text-black text-2xl"
                onClick={closeMenu}
                data-aos="fade-down"
                data-aos-delay={`${(index + 1) * 200}`}
              >
                {navlink.title}
              </h1>
            </Link>
          </li>
        ))}
      </ul>

      {/*Hamburger Menu*/}

      <div
        className={`transition-all transform ease-in-out origin-top duration-300 ${
          isOpen ? "opacity-100 py-2" : " py-0 h-0 [&>*]:hidden opacity-0"
        } absolute right-0 top-20 w-[100%] bg-opacity-90 bg-black shadow-lg z-10 text-center text-2xl lg:hidden`}
      >
        {navlinks.map((navlink, index) => (
          <Link to={navlink.url} key={index}>
            <h1
              className="block px-4 py-1 text-white hover:opacity-60"
              onClick={closeMenu}
            >
              {navlink.title}
            </h1>
          </Link>
        ))}
      </div>

      <div className="md:hidden flex self-center">
        <button className="text-black text-xl md:hidden" onClick={toggleNavbar}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      <a
        href="https://www.instagram.com/momentonemusic/"
        className="hidden md:flex md:justify-center md:items-center"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram
          className="text-3xl hover:scale-110 duration-100"
          data-aos-delay={1200}
          data-aos="fade-down"
        />
      </a>
    </div>
  );
}

export default Header;
