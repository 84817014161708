import React from "react";
import Releases from "../components/Releases";
import Hero from "../components/Hero";
import TextBlock from "../components/TextBlock";
import Writers from "../components/Writers";

function Start() {
  return (
    <div className="flex flex-col items-center justify-center">
      <Hero />
      <Releases />
      <TextBlock />
      <Writers />
    </div>
  );
}

export default Start;
