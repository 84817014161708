import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

import AboutImage from "../images/studios/studio2.webp";

function About() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="relative h-[75vh] flex items-center justify-start w-[100%] bg-black">
        <div className="absolute inset-0 z-0">
          <img
            src={AboutImage}
            alt="Hero"
            loading="lazy"
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
            className="opacity-50"
          />
        </div>

        <div className="relative z-1 flex flex-col mx-5 md:ml-10">
          <h1 className="text-7xl md:text-9xl text-white" data-aos="flip-up">
            About.
          </h1>
          <h6
            className="text-lg md:text-xl w-[90%] md:w-[80%] ml-1 text-white"
            data-aos="zoom-in-left"
          ></h6>
        </div>
      </div>
      <div
        className="text-center flex flex-col items-center min-h-[100vh]"
        data-aos="fade-in"
      >
        <h1 className="md:text-7xl text-5xl text-left mt-10 mx-5 pl-5">
          Welcome to the Momentone Family.
        </h1>
        <div className="text-left max-w-[700px] flex flex-col [&>*]:m-3 [&>*]:text-lg px-5 mb-5">
          <p>
            Nestled in the beating heart of Stockholm, Momentone emerges as an
            exciting new player in the world of music. With a firm dedication to
            cultivating artistic talent, Momentone stands at the crossroads of
            tradition and innovation.
          </p>

          <p>
            Our four studios in central Stockholm serve as a sanctuary for
            artists, writers, and musicians alike, each seeking to bring their
            musical visions to life. Our stable of multi-platinum writers has
            etched their names alongside artists like <b>Alesso</b>,{" "}
            <b>Tungevaag</b>,<b> VINAI</b>, <b>Jon Henrik Fjällgren</b>,{" "}
            <b>Lucas Estrada</b>,<b> Albin Johnsén</b> and many more.
          </p>

          <p>
            Momentone is more than a music publisher, its a vibrant community of
            kindred spirits. We believe that the best creativity blossoms in an
            atmosphere of camaraderie. Whether you're a songwriter, producer, or
            artist, when you step into Momentones world, you're stepping into a
            space where collaboration is nurtured, ideas are shared, and
            artistic growth flourishes.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
